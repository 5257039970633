import React from 'react';
import Section from 'components/Section';
import imageUpload from 'images/how-upload.png';
import imageProcess from 'images/how-process.png';
import imageResult from 'images/how-result.png';
import videoDemo from 'videos/demo.mp4';
import styles from './styles.module.css';

const HowItWorks = () => (
  <Section title={'Cómo Funciona'}>
    <p>
      El producto consiste en una Aplicación que, por medio de Inteligencia Artificial, es aplicada a imágenes de Rayos X en salud dental, cuyo resultado es una identificación del sector dental donde se detecta una o más caries, y que permite mejorar el diagnóstico del profesional en la identificación de las caries dentales.
    </p>
    <div className={styles.stepsContainer}>
      <div className={styles.step}>
        <img src={imageUpload} alt='' />
        <span>La radiografía se sube a través de nuestra plataforma, la cual no requiere de ninguna instalación</span>
      </div>
      <div className={styles.step}>
        <img src={imageProcess} alt='' />
        <span>Nuestra Inteligencia Artificial analiza la radiografía y devuelve resultados en menos de 1 minuto</span>
      </div>
      <div className={styles.step}>
        <img src={imageResult} alt='' />
        <span>Las caries detectadas se muestran en la aplicación, donde el profesional puede analizar los resultados</span>
      </div>
    </div>
    {/* <div> */}
    <video className={styles.video} src={videoDemo} controls={true} />
    {/* </div> */}
  </Section>
);

export default HowItWorks;
