import React from 'react';
import Section from 'components/Section';
import imagePrecision from 'images/benefit-precision.png';
import imageProfitability from 'images/benefit-profitability.png';
import imageVisualisation from 'images/benefit-visualisation.png';
import styles from './styles.module.css';

const Benefits = () => (
  <Section title={'Beneficios'}>
    <div className={styles.container}>
      <div className={styles.benefit}>
        <img src={imagePrecision} alt='' />
        <h3>Precisión</h3>
        <span>Utilizando nuestra IA el profesional será capaz de detectar un 30% más de patologías dentales</span>
      </div>
      <div className={styles.benefit}>
        <img src={imageVisualisation} alt=''/>
        <h3>Visualización</h3>
        <span>Al obtener un resultado visual explicativo se mejora la aceptación del paciente al diagnóstico</span>
      </div>
      <div className={styles.benefit}>
        <img src={imageProfitability} alt='' />
        <h3>Rentabilidad</h3>
        <span>Un rápido pronóstico (menos de un minuto) permite un mayor número de atenciones, mejorando la rentabilidad
</span>
      </div>
    </div>
  </Section>
);

export default Benefits;
