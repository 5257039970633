import React from 'react';
import PropTypes from 'prop-types'
import styles from './styles.module.css';

const Section = ({ children, title }) => {
  return (
    <section id={encodeURI(title)} className={styles.container}>
      <div className={styles.inner}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <div>
          {children}
        </div>
      </div>
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Section