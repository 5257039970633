import React from 'react';
import Section from 'components/Section';
import imageConfidentiality from 'images/value-confidentiality.png';
import imageInnovation from 'images/value-innovation.png';
import imageQuality from 'images/value-quality.png';
import imageTransparency from 'images/value-transparency.png';
import styles from './styles.module.css';

const OurValues = () => (
  <Section title={'Nuestros Valores'}>
    <div className={styles.container}>
      <div>
        <div className={styles.value}>
          <img src={imageInnovation} alt='' />
          <h3>Innovación</h3>
        </div>
        <div className={styles.value}>
          <img src={imageTransparency} alt='' />
          <h3>Transparencia</h3>
        </div>
      </div>
      <div>
        <div className={styles.value}>
          <img src={imageQuality} alt='' />
          <h3>Calidad</h3>
        </div>
        <div className={styles.value}>
          <img src={imageConfidentiality} alt='' />
          <h3>Confidencialidad</h3>
        </div>
      </div>
    </div>
  </Section>
);

export default OurValues;
