import React from 'react'
import Hero from 'components/Hero';
import AboutUs from 'components/AboutUs';
import Benefits from 'components/Benefits';
import HowItWorks from 'components/HowItWorks';
import Layout from 'components/Layout'
import OurValues from 'components/OurValues';
import SEO from 'components/Seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <AboutUs />
    <OurValues />
    <HowItWorks />
    <Benefits />
  </Layout>
)

export default IndexPage
