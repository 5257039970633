import React from 'react';
import { Link } from 'gatsby'
import styles from './styles.module.css';

const Hero = () => (
  <div className={styles.container}>
    <h1 className={styles.title}>
      <span className={styles.titleMain}>Inteligencia Artificial</span>
      <span className={styles.titleSecondary}>Para Aplicaciones en Imagenes Dentales</span>
    </h1>
  </div>
)

export default Hero
