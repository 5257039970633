import React from 'react';
import Section from 'components/Section';
import styles from './styles.module.css';

const AboutUs = () => (
  <Section title={'Nosotros'}>
    <div className={styles.container}>
      <p>BAS-AI ha diseñado un producto que es exclusivo para el mercado de la Odontología en Chile. Somos una empresa que ha puesto la Inteligencia Artificial (IA) a disposición del mundo de la Odontología, por medio de tecnología aplicada a la imagen, para brindarle soluciones en la detección y pronóstico de caries, generando valor para el profesional y el paciente.
      </p>
      <h3>Misión</h3>
      <p>Ser líder en los servicios de imagenología dental aplicando IA, para el mundo Odontológico en Chile.</p>
      <h3>Visión</h3>
      <p>Queremos consolidar BAS-AI, como la empresa pionera en Chile en el uso de IA, de tal manera que, en el mediano y largo plazo, podamos estar presentes en otros ámbitos y sectores de la salud y económicos, tanto nacional como Internacional.</p>
    </div>
  </Section>
);

export default AboutUs;
